<template>
  <el-dialog
    id="FindOrgDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="90%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container>
      <div ref="flex_container" class="flex_container" v-loading="loading">
        <splitpanes class="default-theme" vertical v-if="!singleRecord" style="height: 100%">
          <pane min-size="20" max-size="80" ref="pane1">
            <el-header class="header3" height="48"
              ><el-row type="flex" justify="start">
                <div>
                  <el-input
                    :placeholder="$t('Пошук')"
                    style="width:220px;"
                    prefix-icon="el-icon-search"
                    clearable
                    v-model="filterString"
                    @input="onFilterStringChange"
                    ref="filter"
                    v-mask="mask"
                    @keydown.esc.native="resetFilter($event, this)"
                  />
                </div>
                <div style="line-height:48px;display:flex;align-items:center;padding-left:16px;">
                  <el-dropdown trigger="click" @command="handleClick" v-if="isSmallWidth">
                    <span class="el-dropdown-link">
                      <TIButton :label="$t('Шукати за') + ': ' + searchBy_" dropdown="true" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="find-by-name"
                        ><span :class="{ active: searchBy == 'name' }">{{ $t("Назвою") }}</span></el-dropdown-item
                      >
                      <el-dropdown-item command="find-by-address"
                        ><span :class="{ active: searchBy == 'address' }">{{ $t("Адресою") }}</span></el-dropdown-item
                      >
                      <el-dropdown-item command="find-by-axioma-id"
                        ><span :class="{ active: searchBy == 'axiomaId' }">{{ $t("Axioma ID") }}</span></el-dropdown-item
                      >
                      <el-dropdown-item command="find-by-okpo"
                        ><span :class="{ active: searchBy == 'okpo' }">{{ $t("ЄДРПОУ") }}</span></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-else>
                    <span style="padding-right:8px;">{{ $t("Шукати за") }}</span>
                    <el-radio-group v-model="searchBy" size="small">
                      <el-radio-button label="name">{{ $t("Назвою") }}</el-radio-button>
                      <el-radio-button label="address">{{ $t("Адресою") }}</el-radio-button>
                      <el-radio-button label="axiomaId">{{ $t("Axioma ID") }}</el-radio-button>
                      <el-radio-button label="okpo">{{ $t("ЄДРПОУ") }}</el-radio-button>
                    </el-radio-group>
                  </div>
                  <el-dropdown trigger="click" @command="handleClick" v-if="isSmallWidth">
                    <span class="el-dropdown-link">
                      <TIButton :label="$t('Каталог') + ': ' + lang_" dropdown="true" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="find-by-ua"><span :class="{ active: lang == 'ua' }">UA</span></el-dropdown-item>
                      <el-dropdown-item command="find-by-en"><span :class="{ active: lang == 'en' }">EN</span></el-dropdown-item>
                      <!-- <el-dropdown-item command="find-by-ru"><span :class="{ active: lang == 'ru' }">RU</span></el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-else>
                    <span style="padding-left:16px;padding-right:8px;">{{ $t("Каталог") }}</span>
                    <el-radio-group v-model="lang" size="small">
                      <el-radio-button label="en">{{ $t("EN") }}</el-radio-button>
                      <el-radio-button label="ua">{{ $t("UA") }}</el-radio-button>
                    </el-radio-group>
                  </div>
                  <el-dropdown trigger="click" @command="handleClick" :key="menuKey">
                    <span class="el-dropdown-link">
                      <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="import-org-from-xlsx"
                        ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із Excel (.xlsx)") }}</el-dropdown-item
                      >
                      <el-dropdown-item command="import-org-from-json"
                        ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-row>
            </el-header>
            <div>
              <ag-grid-vue
                ref="findOrgTable"
                id="findOrgTable"
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                rowSelection="multiple"
                :defaultColDef="defaultColDef"
                :columnDefs="columns2"
                :rowData="orgs"
                :headerHeight="32"
                :gridOptions="gridOptions1"
                :overlayNoRowsTemplate="noRowsToShowTemplate"
                :enableBrowserTooltips="true"
                @gridReady="onGridReady1"
                @selection-changed="onGridSelectionChanged1"
                @rowDoubleClicked="onGridDoubleClick1"
                @sortChanged="onGridColumnsChange1"
                @columnResized="onGridColumnsChange1"
                @columnMoved="onGridColumnsChange1"
                @displayedColumnsChanged="onGridColumnsChange1"
                :modules="modules"
                :localeTextFunc="gridLocale"
                :sideBar="sideBar"
                :pivotMode="false"
              >
              </ag-grid-vue>
            </div>
          </pane>
          <pane min-size="20" max-size="80" ref="pane2">
            <div style="line-height:54px;display:flex;align-items:center;padding:8px 0px;background-color:white;" v-if="!singleRecord">
              <TIButton icon="plus_green" @onClick="onAdd" :disabled="!selectedRows" />
              <TIButton icon="delete_red" @onClick="onDel" :disabled="!selectedRows2" />
              <TSeparator />
            </div>
            <div>
              <ag-grid-vue
                ref="findOrgTable2"
                id="findOrgTable2"
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                rowSelection="multiple"
                :defaultColDef="defaultColDef"
                :columnDefs="columns2"
                :headerHeight="32"
                :rowData="selectedOrgs"
                :gridOptions="gridOptions2"
                :overlayNoRowsTemplate="noRowsToShowTemplate"
                @gridReady="onGridReady2"
                @selection-changed="onGridSelectionChanged2"
                @rowDoubleClicked="onGridDoubleClick2"
                @sortChanged="onGridColumnsChange2"
                @columnResized="onGridColumnsChange2"
                @columnMoved="onGridColumnsChange2"
                @displayedColumnsChanged="onGridColumnsChange2"
                :modules="modules"
                :localeTextFunc="gridLocale"
                :sideBar="sideBar"
                :pivotMode="false"
              >
              </ag-grid-vue>
            </div>
          </pane>
        </splitpanes>
        <el-header class="header3" height="48" v-if="singleRecord"
          ><el-row type="flex" justify="start">
            <div>
              <el-input
                :placeholder="$t('Пошук')"
                style="width:220px;"
                prefix-icon="el-icon-search"
                clearable
                v-model="filterString"
                @input="onFilterStringChange"
                ref="filter"
                v-mask="mask"
                @keydown.esc.native="resetFilter($event, this)"
              />
            </div>
            <div style="line-height:48px;display:flex;align-items:center;padding-left:16px;">
              <span style="padding-right:8px;">{{ $t("Шукати за") }}</span>
              <el-radio-group v-model="searchBy" size="small">
                <el-radio-button label="name">{{ $t("Назвою") }}</el-radio-button>
                <el-radio-button label="address">{{ $t("Адресою") }}</el-radio-button>
                <el-radio-button label="axiomaId">{{ $t("Axioma ID") }}</el-radio-button>
                <el-radio-button label="okpo">{{ $t("ЄДРПОУ") }}</el-radio-button>
              </el-radio-group>
              <div style="line-height:48px;display:flex;align-items:center;padding-left:16px;">
                <span style="padding-right:8px;">{{ $t("Каталог") }}</span>
                <el-radio-group v-model="lang" size="small">
                  <el-radio-button label="en">{{ $t("EN") }}</el-radio-button>
                  <el-radio-button label="ua">{{ $t("UA") }}</el-radio-button>
                  <!-- <el-radio-button label="ru">{{ $t("RU") }}</el-radio-button> -->
                </el-radio-group>
              </div>
              <el-dropdown trigger="click" @command="handleClick" :key="menuKey">
                <span class="el-dropdown-link">
                  <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="import-org-from-xlsx"
                    ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із Excel (.xlsx)") }}</el-dropdown-item
                  >
                  <el-dropdown-item command="import-org-from-json"
                    ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-row>
        </el-header>
        <ag-grid-vue
          v-if="singleRecord"
          ref="findOrgTable"
          id="findOrgTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="orgs"
          :gridOptions="gridOptions3"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady3"
          @selection-changed="onGridSelectionChanged3"
          @rowDoubleClicked="onGridDoubleClick2"
          @sortChanged="onGridColumnsChange3"
          @columnResized="onGridColumnsChange3"
          @columnMoved="onGridColumnsChange3"
          @displayedColumnsChanged="onGridColumnsChange3"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :sideBar="sideBar"
          :pivotMode="false"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button
        native-type="submit"
        type="success"
        @click="onCommit"
        class="apply-button"
        :disabled="!allowEmpty && ((singleRecord && !selectedRows) || (!singleRecord && (!selectedOrgs || !selectedOrgs.length)))"
        >{{ $t("Застосувати") }}</el-button
      >
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <SelectFile @commit="orgImportData" bus-event="org.forms.select.json.file" />
    <SelectFileForImportOrgFromXLSShort @commit="orgImportData" bus-event="org.forms.select.xls.file" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "FindOrg",
  props: ["busEvent"],
  components: {
    AgGridVue,
    Splitpanes,
    Pane,
    Icon,
    TIButton,
    TSeparator,
    SelectFile: () => import("@/components/forms/SelectFile"),
    SelectFileForImportOrgFromXLSShort: () => import("@/components/forms/SelectFileForImportOrgFromXLSShort"),
  },
  data() {
    return {
      lang: "ua",
      menuKey: 0,
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      paneWidth: 750,
      resizeObserver: null,
      resizeObserverWidth: null,
      title: $tt("Пошук"),
      target: null,
      useLocalOrAxioma: true,
      model: {},
      initModel: {},
      filterString: "",
      searchBy: "name",
      singleRecord: true,
      allowEmpty: false,
      selectedRows: null,
      selectedRows2: null,
      selectedOrgs: [],
      keyUpTimer: null,
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      gridApi1: null,
      gridApi2: null,
      gridApi3: null,
      gridColumnApi1: null,
      gridColumnApi2: null,
      gridColumnApi3: null,
      gridOptions1: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      gridOptions2: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      gridOptions3: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: [
        {
          headerName: $tt("Назва"),
          field: "name",
          flex: 1,
        },
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 1,
        },
        {
          headerName: "AxiomaID",
          field: "axiomaId",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Юридична особа"),
          field: "isEntity",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
          },
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      columns2: [
        {
          headerName: $tt("Назва"),
          field: "name",
          flex: 1,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 1,
        },
        {
          headerName: "AxiomaID",
          field: "axiomaId",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Юридична особа"),
          field: "isEntity",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
          },
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.target = (data && data.target) || "unknown";
        this.operation = (data && data.operation) || "create";
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.singleRecord = data ? data.singleRecord : true;
        this.allowEmpty = data ? data.allowEmpty : false;
        this.$store.dispatch("orgs/init");
        this.filterString = "";
        this.selectedOrgs = data && data.selectedOrgs ? data.selectedOrgs : [];
        this.dialogShow = true;
      }
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    orgs() {
      return this.$store.getters["orgs/store"];
    },

    loading() {
      return this.$store.getters["orgs/wait"]();
    },
    mask() {
      switch (this.searchBy) {
        case "name":
          return undefined;
        case "address":
          return undefined;
        case "axiomaId":
          return "###########";
        case "okpo":
          return "###########";
      }
    },
    isSmallWidth() {
      return this.paneWidth < 970;
    },
    searchBy_() {
      switch (this.searchBy) {
        case "name":
          return $tt("Назвою");
        case "address":
          return $tt("Адресою");
        case "axiomaId":
          return $tt("Axioma ID");
        case "okpo":
          return $tt("ЄДРПОУ");
      }
    },
    lang_() {
      return this.lang.toUpperCase();
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 124 + 48 * (this.singleRecord ? 0 : 1);
      this.$refs.flex_container.style.height = `${containerHeight}px`;
      this.$refs.flex_container.style.width = `100%`;
      if (!this.singleRecord) {
        this.$refs.flex_container.children[0].children[0].children[1].style.height = `${containerHeight - 48}px`;
        this.$refs.flex_container.children[0].children[2].children[1].style.height = `${containerHeight - 48}px`;
      }
    },
    searchBy(value) {
      this.resetFilter();
      this.$store.dispatch("main/updateUserSettings", { findOrgSearchBy: value });
    },
    lang(value) {
      this.resetFilter();
      this.$store.dispatch("main/updateUserSettings", { userSettings: { defaultCatalogLang: value } });
    },
  },
  mounted() {
    this.searchBy = this.$store.getters["main/userSettings"]("findOrgSearchBy") || "name";
    this.lang = this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang;
  },
  methods: {
    onGridReady1() {
      this.gridApi1 = this.gridOptions1.api;
      this.gridColumnApi1 = this.gridOptions1.columnApi;
      var state = this.$store.getters["main/userSettings"]("findOrgGridColumnState1");
      if (state) this.gridColumnApi1.setColumnState(state);
      this.checkGridSettingsToChanges(this, $tt("Структури"), "findOrgGridColumnState1", this.columns);
    },
    onGridReady2() {
      this.gridApi2 = this.gridOptions2.api;
      this.gridColumnApi2 = this.gridOptions2.columnApi;
      var state = this.$store.getters["main/userSettings"]("findOrgGridColumnState2");
      if (state) this.gridColumnApi2.setColumnState(state);
    },
    onGridReady3() {
      this.gridApi3 = this.gridOptions3.api;
      this.gridColumnApi3 = this.gridOptions3.columnApi;
      var state = this.$store.getters["main/userSettings"]("findOrgGridColumnState3");
      if (state) this.gridColumnApi3.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    observeWidth() {
      var that = this;
      if (!this.resizeObserverWidth) {
        if (that.$refs.pane1) {
          this.resizeObserverWidth = new ResizeObserver(function() {
            if (that.$refs.pane1 && that.$refs.pane1.$el) that.paneWidth = that.$refs.pane1.$el.clientWidth;
          });
          if (this.$refs.pane1) this.resizeObserverWidth.observe(this.$refs.pane1.$el);
        }
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    unobserveWidth() {
      this.resizeObserverWidth && this.$refs.pane1 && this.resizeObserverWidth.unobserve(this.$refs.pane1.$el);
      this.resizeObserverWidth = null;
      this.paneWidth = 750;
    },

    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        if (this.loading) {
          this.$store.dispatch("main/cancelAllRequests");
        } else this.$refs.filter.clear();
      }
    },
    onOpen() {
      this.observeHeight();
      this.menuKey = this.menuKey + 1;
    },
    onOpened() {
      this.$refs.filter.focus();
      this.observeWidth();
      this.menuKey = this.menuKey + 1;
    },
    onClose() {
      this.$store.dispatch("main/cancelAllRequests");
      this.resetFilter();
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
      this.unobserveWidth();
    },
    importOrgFromJSON() {
      bus.$emit("org.forms.select.json.file.show", {
        title: $tt("Завантаження організацій"),
        operation: "contract.org.import.json",
        initModel: {},
      });
    },
    importOrgFromXLS() {
      bus.$emit("org.forms.select.xls.file.show", {
        title: $tt("Завантаження організацій"),
        operation: "contract.org.import.xls",
        initModel: { orgExists: [], lang: this.lang },
      });
    },
    orgImportData(data) {
      var that = this;
      if (data.operation == "contract.org.import.xls") {
        if (data && data.data.length) {
          if (this.singleRecord) {
            this.$store.dispatch("orgs/add", data.data);
          } else {
            var existIds = this.selectedOrgs.map((item) => item.axiomaId);
            var newOrgs = data.data.filter((item) => existIds.indexOf(item.axiomaId) == -1);
            this.selectedOrgs = this.selectedOrgs.concat(newOrgs);
          }
        }
      } else if (data.operation == "contract.org.import.json") {
        that
          .readJSONFile(data.file)
          .then((result) => {
            if (result && result.dataType && (result.dataType == "orgs" || result.dataType == "structures") && result.data) {
              this.$store.dispatch("orgs/add", result.data);
            } else {
              globalErrorMessage($tt("Помилка імпорту"), $tt("Помилка в структурі JSON файлу"));
            }
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка імпорту"), err);
          });
      }
    },
    handleClick(command) {
      switch (command) {
        case "import-org-from-xlsx": {
          this.importOrgFromXLS();
          break;
        }
        case "import-org-from-json": {
          this.importOrgFromJSON();
          break;
        }
        case "find-by-name": {
          this.searchBy = "name";
          break;
        }
        case "find-by-address": {
          this.searchBy = "address";
          break;
        }
        case "find-by-axioma-id": {
          this.searchBy = "axiomaId";
          break;
        }
        case "find-by-okpo": {
          this.searchBy = "okpo";
          break;
        }
        case "find-by-ua": {
          this.lang = "ua";
          break;
        }
        case "find-by-en": {
          this.lang = "en";
          break;
        }
        case "find-by-ru": {
          this.lang = "ru";
          break;
        }
        default:
          break;
      }
    },
    onCommit() {
      if (this.singleRecord) {
        if (this.selectedRows) {
          this.onClose();
          this.$emit("find-commit", { target: this.target, result: this.selectedRows });
          this.selectedRows = null;
        } else if (this.allowEmpty) {
          this.onClose();
          this.$emit("find-commit", { target: this.target, result: null });
        } else this.$message.error($tt("Будь ласка, виберіть один рядок в результатах пошуку!"));
      } else {
        if (this.selectedOrgs && this.selectedOrgs.length) {
          this.onClose();
          this.$emit("find-commit", { target: this.target, result: this.selectedOrgs });
          this.selectedOrgs = null;
        } else {
          if (this.allowEmpty) {
            this.onClose();
            this.$emit("find-commit", { target: this.target, result: null });
          } else this.$message.error($tt("Будь ласка, додайте один або декілька рядків в нижню таблицю!"));
        }
      }
    },

    onAdd() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        var existIds = this.selectedOrgs.map((item) => item.axiomaId);
        var newOrgs = this.selectedRows.filter((item) => existIds.indexOf(item.axiomaId) == -1);
        this.selectedOrgs = this.selectedOrgs.concat(newOrgs);
        this.$store.dispatch("orgs/del", newOrgs);
      }
    },
    onDel() {
      if (this.selectedRows2 && this.selectedRows2.length > 0) {
        var delIds = this.selectedRows2.map((item) => item.axiomaId);
        this.selectedOrgs = this.selectedOrgs.filter((item) => delIds.indexOf(item.axiomaId) == -1);
        this.$store.dispatch("orgs/add", this.selectedRows2);
      }
    },
    onFilterStringChange(value, timeout) {
      var that = this;
      if (value == "") {
        this.$store.dispatch("orgs/init");
        this.$store.dispatch("main/cancelAllRequests");
      } else {
        if (this.keyUpTimer) clearTimeout(this.keyUpTimer);
        this.keyUpTimer = setTimeout(
          () => {
            that.keyUpTimer = null;
            that.runQueryFilter(value);
          },
          timeout ? timeout : 600
        );
      }
    },
    runQueryFilter(value) {
      if (value && value.length >= 3) {
        this.$store.dispatch("main/cancelAllRequests").then(() => {
          //var findType= this.useLocalOrAxioma ? "axioma" : "local";
          var org_types = [];
          var queryType = "";
          if (this.searchBy == "name" || this.searchBy == "address") org_types = { customer: [9], executor: [2, 337, 385], providers: [337, 385] }[this.target];
          if (this.searchBy == "name" || this.searchBy == "address")
            queryType = { customer: "customers", executor: "executors", providers: "suppliers" }[this.target];
          this.$store
            .dispatch("orgs/get_list", { filter: value, org_types, queryType, findType: "axioma", searchBy: this.searchBy, lang: this.lang })
            .then((data) => {})
            .catch((err) => {
              this.$message.error(parseError(err.data.msg));
              this.$store.dispatch("orgs/init");
            })
            .finally((data) => {});
        });
      }
    },
    onGridSelectionChanged1() {
      var rows = this.gridOptions1.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = null;
      }
    },
    onGridSelectionChanged2() {
      var rows = this.gridOptions2.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows2 = rows;
      } else {
        this.selectedRows2 = null;
      }
    },
    onGridSelectionChanged3() {
      var rows = this.gridOptions3.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = null;
      }
    },
    onGridDoubleClick() {
      this.onCommit();
    },
    onGridDoubleClick1() {
      this.onAdd();
    },
    onGridDoubleClick2() {
      this.onDel();
    },
    onFormEnter() {
      if (!this.singleRecord) this.onAdd();
    },
    onGridColumnsChange1() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi1.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { findOrgGridColumnState1: state });
      }, 500);
    },
    onGridColumnsChange2() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi2.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { findOrgGridColumnState2: state });
      }, 500);
    },
    onGridColumnsChange3() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi3.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { findOrgGridColumnState3: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
  },
};
</script>

<style lang="scss" scope>
#FindOrgDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#FindOrgDialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}

#FindOrgDialog .el-loading-mask {
  top: 48px !important;
  bottom: -48px !important;
}
span.active {
  font-weight: 700;
}
</style>
